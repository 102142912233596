<template>
    <!-- 智慧服务-》校园生活-》一卡通对账 -->
    <div class="card-checking-Wrapper">
        <tabbed-page
            :tabList="tabList"
            @changeTabs="handlerChangeTabs"
        >
        </tabbed-page>
        <component
            :is="comps[idx]"
            :accessStatus="accessStatus"
            :key="idx"
            :type="tabList[idx].type"
            @statisticsDetail="handlerStatisticsDetail"
        >
        </component>
    </div>
</template>
  <script>
import TabbedPage from "./Sub/TabbedPage/index.vue";
import checkingStatistics from "@/components/scrollWrapper/cardChecking/statistics";
import {tabPageHasAuth} from "@/libs/tabbedPageAuth.js";
export default {
    name: "CardCheckingWrapper",
    components: {
        TabbedPage,
        checkingStatistics,
        account: () =>
            import(
                /* webpackChunkName:'account' */ "@/components/scrollWrapper/cardChecking/account"
            ),
        setting: () =>
            import(
                /* webpackChunkName:'setting' */ "@/components/scrollWrapper/cardChecking/setting"
            ),
    },
    props: {
        accessStatus: String,
    },
    data() {
        return {
            tabList: [
                {
                    id: "first",
                    title: "对账报表",
                    com: "checkingStatistics",
                    type: "checkingStatistics",
                },
                {
                    id: "second",
                    title: "全部账单",
                    com: "account",
                    type: "all",
                },
                {
                    id: "third",
                    title: "异常账单",
                    com: "account",
                    type: "unusual",
                },
                // {
                //     id: "fourth",
                //     title: "异常账单日志",
                //     com: "account",
                //     type: "unusualLog",
                // },
                {
                    id: "fourth",
                    title: "充值通道设置",
                    com: "setting",
                    type: "setting",
                },
            ],
            comps: [],
            idx: 0,
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.tabList = tabPageHasAuth(this.tabList);
            this.comps = this.tabList.map((item) => item.com);
            console.log(this.comps, this.tabList, "this.tabList");
            this.handlerChangeTabs(this.tabList[0]);
        },
        handlerChangeTabs(data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, "sel", true);
                    this.idx = index;
                } else {
                    this.$set(item, "sel", false);
                }
            });
            console.log(this.tabList, "this.tabList");
        },
        changeBtn(data) {
            this.$eventBus.$emit("changeBtn", data);
        },
        handlerStatisticsDetail(data) {
            console.log(data.date, "data.date");
            localStorage.setItem("accountDate", data.date);
            this.handlerChangeTabs(this.tabList[1]);
        },
    },
};
</script>
  