<template>
    <!-- 智慧服务 > 校园生活 > 一卡通对账 > 对账报表 -->
    <div
        class="content-main"
        style="margin-top: 0px"
    >
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <!-- 筛选条件 -->
                <expand-filter
                    :formData="formData"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    margin-bottom="0"
                >
                    <template slot="other-btn">
                        <span class="tip-icon" @click="tipShow=true">
                            <i class="el-icon-question"></i>
                            数据说明
                        </span>
                    </template>
                </expand-filter>
                
                <!-- 统计结果 -->
                <div class="statistics-card">
                    <div class="card-item result">
                        <div class="title">统计结果</div>
                        <div class="content first">
                            <div class="item">
                                支付未充值
                                <span
                                    :class="[
                                        'number',
                                        statisticsData.paymentIsNotRecharged
                                            .totalOrderCount > 0
                                            ? 'red'
                                            : '',
                                    ]"
                                    >{{
                                        statisticsData.paymentIsNotRecharged
                                            .totalOrderCount
                                    }}</span
                                >
                                笔
                                <span
                                    :class="[
                                        'number',
                                        statisticsData.paymentIsNotRecharged
                                            .totalAmount > 0
                                            ? 'red'
                                            : '',
                                    ]"
                                    >{{
                                        statisticsData.paymentIsNotRecharged
                                            .totalAmount
                                    }}</span
                                >
                                元
                            </div>
                            <div class="item">
                                充值未到账
                                <span
                                    :class="[
                                        'number',
                                        statisticsData.rechargeHasNotArrived
                                            .totalOrderCount > 0
                                            ? 'red'
                                            : '',
                                    ]"
                                    >{{
                                        statisticsData.rechargeHasNotArrived
                                            .totalOrderCount
                                    }}</span
                                >
                                笔
                                <span
                                    :class="[
                                        'number',
                                        statisticsData.rechargeHasNotArrived
                                            .totalAmount > 0
                                            ? 'red'
                                            : '',
                                    ]"
                                    >{{
                                        statisticsData.rechargeHasNotArrived
                                            .totalAmount
                                    }}</span
                                >
                                元
                            </div>
                        </div>
                    </div>
                    <div class="card-item detail">
                        <div class="title">统计详情</div>
                        <div class="content first">
                            <div class="item">
                                银行充值总订单数
                                <span class="number">{{
                                    statisticsData.bankToUpOrderCount
                                }}</span>
                                笔
                            </div>
                            <div class="item">
                                银行充值总金额
                                <span class="number">{{
                                    statisticsData.bankToUpTotalAmount
                                }}</span>
                                元
                            </div>
                        </div>
                        <div class="content">
                            <div class="item">
                                充值服务总订单数
                                <span
                                    :class="[
                                        'number',
                                        statisticsData.bankToUpOrderCount !=
                                        statisticsData.toUpServiceOrderCount
                                            ? 'red'
                                            : '',
                                    ]"
                                    >{{
                                        statisticsData.toUpServiceOrderCount
                                    }}</span
                                >
                                笔
                            </div>
                            <div class="item">
                                充值服务总金额
                                <span
                                    :class="[
                                        'number',
                                        statisticsData.bankToUpTotalAmount !=
                                        statisticsData.toUpServiceTotalAmount
                                            ? 'red'
                                            : '',
                                    ]"
                                    >{{
                                        statisticsData.toUpServiceTotalAmount
                                    }}</span
                                >
                                元
                            </div>
                        </div>
                        <div class="content">
                            <div class="item">
                                一卡通到账总订单数
                                <span
                                    :class="[
                                        'number',
                                        statisticsData.toUpServiceOrderCount !=
                                        statisticsData.yktArrivalOrderCount
                                            ? 'red'
                                            : '',
                                    ]"
                                    >{{
                                        statisticsData.yktArrivalOrderCount
                                    }}</span
                                >
                                笔
                            </div>
                            <div class="item">
                                一卡通到账总金额
                                <span
                                    :class="[
                                        'number',
                                        statisticsData.toUpServiceTotalAmount !=
                                        statisticsData.yktArrivalTotalAmount
                                            ? 'red'
                                            : '',
                                    ]"
                                    >{{
                                        statisticsData.yktArrivalTotalAmount
                                    }}</span
                                >
                                元
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 表格 -->
                <table-data
                    v-loading="loadingTable"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                >
                    <!-- 银行充值总订单数 -->
                    <template v-slot:bankToUpOrderCount="slotData">
                        <span>
                            {{ slotData.data.bankToUpOrderCount }}
                        </span>
                    </template>
                    <!-- 银行充值总金额 -->
                    <template v-slot:bankToUpTotalAmount="slotData">
                        <span>
                            {{ slotData.data.bankToUpTotalAmount }}
                        </span>
                    </template>
                    <!-- 充值服务总订单数 -->
                    <template v-slot:toUpServiceOrderCount="slotData">
                        <span :class="{ 'red': slotData.data.toUpServiceOrderCount != slotData.data.bankToUpOrderCount }">
                            {{ slotData.data.toUpServiceOrderCount }}
                        </span>
                    </template>
                    <!-- 充值服务总金额 -->
                    <template v-slot:toUpServiceTotalAmount="slotData">
                        <span :class="{ 'red': slotData.data.toUpServiceTotalAmount != slotData.data.bankToUpTotalAmount }">
                            {{ slotData.data.toUpServiceTotalAmount }}
                        </span>
                    </template>
                    <!-- 一卡通到账总订单数 -->
                    <template v-slot:yktArrivalOrderCount="slotData">
                        <span :class="{ 'red': slotData.data.yktArrivalOrderCount != slotData.data.toUpServiceOrderCount }">
                            {{ slotData.data.yktArrivalOrderCount }}
                        </span>
                    </template>
                    <!-- 一卡通到账总金额 -->
                    <template v-slot:yktArrivalTotalAmount="slotData">
                        <span :class="{ 'red': slotData.data.yktArrivalTotalAmount != slotData.data.toUpServiceTotalAmount }">
                            {{ slotData.data.yktArrivalTotalAmount }}
                        </span>
                    </template>
                    <!-- 未充值订单数 -->
                    <template v-slot:wczDdCount="slotData">
                        <span :class="{ 'red': slotData.data.wczDdCount > 0 }">
                            {{ slotData.data.wczDdCount }}
                        </span>
                    </template>
                    <!-- 未充值总金额 -->
                    <template v-slot:wczDdAmount="slotData">
                        <span :class="{ 'red': slotData.data.wczDdAmount > 0 }">
                            {{ slotData.data.wczDdAmount }}
                        </span>
                    </template>
                    <!-- 未到账订单数 -->
                    <template v-slot:wdzDdCount="slotData">
                        <span :class="{ 'red': slotData.data.wdzDdCount > 0 }">
                            {{ slotData.data.wdzDdCount }}
                        </span>
                    </template>
                    <!-- 未到账总金额 -->
                    <template v-slot:wdzDdAmount="slotData">
                        <span :class="{ 'red': slotData.data.wdzDdAmount > 0 }">
                            {{ slotData.data.wdzDdAmount }}
                        </span>
                    </template>
                    <template v-slot:operation="slotData">
                        <el-button
                            type="text"
                            class="primary"
                            @click="download(slotData.data)"
                            >下载报表</el-button
                        >
                        <el-button
                            type="text"
                            class="primary"
                            @click="goDetail(slotData.data)"
                            >查看详情</el-button
                        >
                    </template>
                </table-data>
                <Pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
        <!-- 数据说明弹窗 -->
        <el-dialog
            title="数据说明"
            :visible.sync="tipShow"
            width="640px"
            :modal-append-to-body="false"
            center
        >
            <div class="content" style="line-height: 32px;margin-bottom: 20px">
                <p>系统每日8:00汇总前一日银行订单及其状态，但请注意以下潜在情况：</p>
                <p>1、若银行订单支付状态在汇总后发生变动，系统将无法实时更新此状态。</p>
                <p>2、若银行账户用于智慧校园卡充值页面之外的收付款，可能引发大量订单状态异常。</p>
                <p>3、鉴于当前系统未纳入退款数据测试，退款操作可能导致银行流水记录与实际不符。</p>
                <p>4、用户取消支付或支付失败时，系统获取不到银行的订单时间，所以其订单时间统一标记为当日的00:01。</p>
                <p>5、强烈建议避免单一支付ID跨多所学校使用，以免混淆账单信息。如有此需求，请联系运营人员，通过同一账户申请多个支付ID以区分管理。</p>
                <p style="color:#d97716">特别说明：通过智慧校园卡充值页面生成的订单，则不受上述限制影响。</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    // 业务组件
    Pagination,
    // 功能组件
    Loading,
    Error,
} from "common-local";
import TableData from "../Sub/TableData";

import {mapState} from "vuex";
import {CardCheckingModel} from "@/models/cardChecking";
import ExpandFilter from "../Sub/ExpandFilter";
import onResize from "@/mixins/onResize";
import { downloadFile} from "@/libs/utils";

export default {
    name: "cardCheckingStatistics",
    mixins: [onResize],
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        Loading,
        Error,
    },
    data() {
        const _this = this;
        return {
            tipShow: false,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "datePick",
                        label: "",
                        startPlaceholder: "订单开始日期",
                        endPlaceholder: "订单结束日期",
                        key: "payTimeRadius",
                        value: [
                            _this.$moment().subtract(1, 'months').add(1, 'days').format('YYYY-MM-DD'),
                            _this.$moment().format('YYYY-MM-DD'),
                        ],
                        picker: true,
                        format: "yyyy-MM-dd",
                        pickerOptions: {
                            disabledDate(time) {
                                return (
                                    time.getTime() > Date.now() ||
                                    time.getTime() <
                                        Date.now() - 3600 * 1000 * 24 * 183
                                );
                            },
                            shortcuts: [
                                {
                                    text: "最近一周",
                                    onClick(picker) {
                                        const end = new Date();
                                        const start = new Date();
                                        start.setTime(
                                            start.getTime() -
                                                3600 * 1000 * 24 * 6,
                                        );
                                        picker.$emit("pick", [start, end]);
                                    },
                                },
                                {
                                    text: "最近一个月",
                                    onClick(picker) {
                                        const end = new Date();
                                        const start = new Date();
                                        const startTime = _this.$moment().subtract(1, 'months').add(1, 'days').valueOf();
                                        start.setTime(startTime);
                                        picker.$emit("pick", [start, end]);
                                    },
                                },
                                {
                                    text: "最近三个月",
                                    onClick(picker) {
                                        const end = new Date();
                                        const start = new Date();
                                        const startTime = _this.$moment().subtract(3, 'months').add(1, 'days').valueOf();
                                        start.setTime(startTime);
                                        picker.$emit("pick", [start, end]);
                                    },
                                },
                            ],
                        },
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        // auth: ["handOver:list"],
                    },
                ],
            },
            // 统计数据
            statisticsData: {
                paymentIsNotRecharged: {},
                rechargeHasNotArrived: {},
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "日期",
                        prop: "date",
                        align: "center",
                    },
                    {
                        label: "银行充值总订单数",
                        type: "slot",
                        align: "center",
                        slotName: "bankToUpOrderCount",
                    },
                    {
                        label: "银行充值总金额",
                        type: "slot",
                        align: "center",
                        slotName: "bankToUpTotalAmount",
                    },
                    {
                        label: "充值服务总订单数",
                        type: "slot",
                        align: "center",
                        slotName: "toUpServiceOrderCount",
                    },
                    {
                        label: "充值服务总金额",
                        type: "slot",
                        align: "center",
                        slotName: "toUpServiceTotalAmount",
                    },
                    {
                        label: "一卡通到账总订单数",
                        type: "slot",
                        align: "center",
                        slotName: "yktArrivalOrderCount",
                    },
                    {
                        label: "一卡通到账总金额",
                        type: "slot",
                        align: "center",
                        slotName: "yktArrivalTotalAmount",
                    },
                    {
                        label: "未充值订单数",
                        type: "slot",
                        align: "center",
                        slotName: "wczDdCount",
                    },
                    {
                        label: "未充值总金额",
                        type: "slot",
                        align: "center",
                        slotName: "wczDdAmount",
                    },
                    {
                        label: "未到账订单数",
                        type: "slot",
                        align: "center",
                        slotName: "wdzDdCount",
                    },
                    {
                        label: "未到账总金额",
                        type: "slot",
                        align: "center",
                        slotName: "wdzDdAmount",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        align: "center",
                        slotName: "operation",
                        labelWidth: "200",
                    },
                ],
                showIndex: true,
                check: false,
                height: "",
                leftEmpty: true,
            },
            // 表格数据
            table_data: [],
            // 分页器
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: this.$store.state.schoolId,
                payTimeRadius: [],
            },
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,
        };
    },
    computed: {
        ...mapState({
            templateInfo: (state) => state.templateInfo,
            schoolId: (state) => state.schoolId,
        }),
    },
    created() {
        this.clickPrimary();
    },
    mounted() {},
    methods: {
        /**
         * @Description: 检索表单按钮点击事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-08-06 11:52:10
         * @param {*} ev
         */
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary":
                    this.clickPrimary();
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 查询
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-08-28 14:37:19
         */
        clickPrimary() {
            this.formData.data.forEach((item) => {
                this.listQuery[item.key] = item.value;
            });
            if(!this.listQuery.payTimeRadius){
                this.$message.warning('请选择订单时间范围')
                return
            }
            this.getStastics();
            this.getList(1);
        },
        /**
         * @Description: 获取统计结果
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-08-06 11:55:43
         */
        getStastics() {
            const cardCheckingApi = new CardCheckingModel();
            let params = {
                schoolId: this.schoolId,
                payTimeRadius: this.listQuery.payTimeRadius,
            };
            cardCheckingApi.getBillStatistics(params).then((res) => {
                this.handleRes(res, () => {
                    this.statisticsData = res.data.data;
                    this.loadingTable = false;
                });
            });
        },
        /**
         * @Description: 获取列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-08-06 11:55:01
         * @param {*} t
         */
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            const cardCheckingApi = new CardCheckingModel();
            cardCheckingApi
                .getBillStatisticsDetail(this.listQuery)
                .then((res) => {
                    this.handleRes(res, () => {
                        this.table_data = res.data.data.list;
                        this.total = res.data.data.totalCount;
                        this.loadingTable = false;
                    });
                });
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        /**
         * @Description: 下载报表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-08-06 10:46:55
         * @param {*} row
         */
        download(row) {
            downloadFile(
                {
                    url: "/consume/yktOrder/exportYktOrderCheckAccountsInfo",
                    form: {
                        schoolId: this.schoolId,
                        payTimeRadius: [row.date, row.date],
                        orderTabFlag: '1',
                    },
                },
                () => {
                    // this.$message.success("下载成功");
                },
            );
        },
        /**
         * @Description: 查看详情-携带当前的时间参数，跳转到全部账单页
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-08-06 11:53:36
         * @param {*} row
         */
        goDetail(row) {
            this.$emit("statisticsDetail", row);
        },
    },
};
</script>
<style lang="scss" scoped>
.statistics-card {
    background: #fff;
    padding: 0 10px 10px 10px;
    display: flex;

    .card-item {
        display: inline-flex;
        align-items: center;
        padding: 8px 20px 8px 0;
        border-radius: 6px;
        font-size: 12px;

        &.result {
            background: linear-gradient(90deg, #fcf0e3 0%, #fceae8 100%);
            margin-right: 8px;

            .title {
                color: #d97716;
            }

            .content {
                .item {
                    border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
                    padding-right: 20px;
                }
            }
        }

        &.detail {
            background: linear-gradient(90deg, #e3f2fc 0%, #f0f2fc 100%);

            .title {
                color: #2284e6;
            }
        }

        .title {
            padding: 3px 10px;
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            width: 12px;
            margin-right: 15px;
        }

        .content {
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            padding: 8px 0;

            &:last-child {
                border-right: none;
            }

            &.first {
                .item {
                    padding-left: 0;
                }
            }

            .item {
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                padding-right: 40px;
                padding-left: 15px;
                padding-bottom: 3px;
                display: flex;
                align-items: center;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                    padding-top: 3px;
                }
                .number {
                    font-weight: bold;
                    font-size: 20px;
                    color: #2b2f33;
                    margin: 0 7px 0 15px;

                    &.red {
                        color: #e64839;
                    }
                }
            }
        }
    }
}

.el-table .cell span.red {
    color: #e64839;
}

.tip-icon {
    color: #d97716;
    font-size: 14px;
    line-height: 32px;
    cursor: pointer;

    i {
        margin-left: 20px;
        font-size: 16px;
    }
}

</style>
